import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CircleNotch } from "phosphor-react";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  position: Yup.string().required("Required"),
  location: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  resume: Yup.mixed()
    .required("Required")
    .test(
      "fileSize",
      "File exceeds 5 MB size limit. Please upload a smaller file.",
      (value) => value && value.size <= 5 * 1024 * 1024
    ),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  position: "Cashier",
  location: "",
  phoneNumber: "",
  resume: null,
};

const jobPositions = [
  "Cashier",
  "Delivery driver",
  "Pizza chef",
  "Line cook",
  "Prep cook/dishwasher",
  "Social media manager",
];

const locations = ["Bensonhurst", "Sunset Park"];

const JobApplicationForm = () => {
  const [submissionError, setSubmissionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      try {
        const response = await fetch("https://api.robsofbrooklyn.com/jobs", {
          method: "POST",
          body: formData,
        });

        // Handle the API response
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            setSubmissionSuccess(true);
            setSuccessMessage(responseData.message);

            formik.resetForm();
            formik.setFieldValue("resume", null);
            setTimeout(() => {
              setSubmissionSuccess(false);
            }, 5000);
          }
        } else {
          setSubmissionError(true);
          setErrorMessage(
            "There was an error submitting your application. Please try again later."
          );
        }
      } catch (error) {
        console.error("Error submitting application:", error);
        setErrorMessage(
          "There was an error submitting your application. Please try again later."
        );
        setSubmissionError(true);
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mx-auto font-typewriter bg-white pt-6 pb-8 mb-4"
    >
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-bold mb-2" htmlFor="firstName">
            First Name <span className="text-brand-red">*</span>
          </label>
          <input
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          {formik.errors.firstName && formik.touched.firstName && (
            <p className="text-red-700 text-xs mt-1">{formik.errors.firstName}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-bold mb-2" htmlFor="lastName">
            Last Name <span className="text-brand-red">*</span>
          </label>
          <input
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          {formik.errors.lastName && formik.touched.lastName && (
            <p className="text-red-700 text-xs mt-1">{formik.errors.lastName}</p>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <label className="block text-sm font-bold mb-2" htmlFor="email">
            Email <span className="text-brand-red">*</span>
          </label>
          <input
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && formik.touched.email && (
            <p className="text-red-700 text-xs mt-1">{formik.errors.email}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-bold mb-2" htmlFor="phoneNumber">
            Phone Number <span className="text-brand-red">*</span>
          </label>
          <input
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="phoneNumber"
            name="phoneNumber"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
          />
          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
            <p className="text-red-700 text-xs mt-1">
              {formik.errors.phoneNumber}
            </p>
          )}
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="position">
          Job Position <span className="text-brand-red">*</span>
        </label>
        <select
          className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="position"
          name="position"
          onChange={formik.handleChange}
        >
          {jobPositions.map((position, index) => (
            <option key={index} value={position}>
              {position}
            </option>
          ))}
        </select>
        {formik.errors.position && formik.touched.position && (
          <p className="text-red-700 text-xs mt-1">{formik.errors.position}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="location">
          Location <span className="text-brand-red">*</span>
        </label>
        <select
          className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="location"
          name="location"
          onChange={formik.handleChange}
        >
          {locations.map((location, index) => (
            <option key={index} value={location}>
              {location}
            </option>
          ))}
        </select>
        {formik.errors.location && formik.touched.location && (
          <p className="text-red-700 text-xs mt-1">{formik.errors.location}</p>
        )}
      </div>

      <div className="mb-4">
        <label className="block text-sm font-bold mb-2" htmlFor="resume">
          Resume <span className="text-brand-red">*</span>
          <span className="text-xs ml-2 font-normal">(Max upload size 5mb)</span>
        </label>
        <input
          className="border-2 border-gray-200 rounded w-full px-3 leading-tight focus:outline-none focus:shadow-outline"
          id="resume"
          name="resume"
          type="file"
          onChange={(event) => {
            formik.setFieldValue("resume", event.currentTarget.files[0]);
          }}
        />
        {formik.errors.resume && (
          <p className="text-red-700 text-xs mt-1">{formik.errors.resume}</p>
        )}
      </div>

      {submissionError ? (
        <div className="flex items-center bg-red-50 font-bold tracking-wide text-brand-red-dark py-2 px-4 mb-4 rounded-sm">
          <div>{errorMessage}</div>
        </div>
      ) : null}
      
      {submissionSuccess ? (
        <div className="flex items-center bg-green-50 font-bold tracking-wide text-brand-green py-2 px-4 mb-4 rounded-sm">
          <div className="">{successMessage}</div>
        </div>
      ) : null}

      <div className="flex items-center justify-center">
        <button
          className={`flex gap-2 justify-center w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
            ${
              formik.isSubmitting
                ? "bg-red-50 text-brand-red-dark cursor-not-allowed"
                : "bg-brand-red hover:bg-brand-red-dark text-white"
            }`}
          type="submit"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <CircleNotch weight={"bold"} className="animate-spin" size={24} />
          ) : null}
          {formik.isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default JobApplicationForm;