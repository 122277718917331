import React from "react";
import Doordash from "../assets/brand-logos/doordash.svg";
import Grubhub from "../assets/brand-logos/grubhub.svg";
import Seamless from "../assets/brand-logos/seamless.svg";
import Slice from "../assets/brand-logos/slice.svg";
import UberEats from "../assets/brand-logos/ubereats.svg";
import Postmates from "../assets/brand-logos/postmates.svg";
import Hero from "../components/order/Hero";
import { PhoneCall } from "phosphor-react";

const bensonhurstPlatforms = [
  {
    name: "Doordash",
    url: "https://www.doordash.com/store/rob's-of-brooklyn-brooklyn-24011033/",
    logo: Doordash,
  },
  {
    name: "Grubhub",
    url: "https://www.grubhub.com/restaurant/robs-of-brooklyn-8774b-bay-parkway-brooklyn/4381368",
    logo: Grubhub,
  },
  {
    name: "Postmates",
    url: "https://postmates.com/store/robs-of-brooklyn/wWt6q99WRdqKNbD-j5rRwg",
    logo: Postmates,
  },
  {
    name: "Seamless",
    url: "https://www.seamless.com/menu/robs-of-brooklyn-8774b-bay-parkway-brooklyn/4381368",
    logo: Seamless,
  },
  {
    name: "Slice",
    url: "https://slicelife.com/restaurants/ny/brooklyn/11214/rob-s-of-brooklyn/menu",
    logo: Slice,
  },
  {
    name: "Uber Eats",
    url: "https://www.ubereats.com/store/robs-of-brooklyn/wWt6q99WRdqKNbD-j5rRwg",
    logo: UberEats,
  },
];

const sunsetParkPlatforms = [
  {
    name: "Doordash",
    url: "https://www.doordash.com/store/rob%E2%80%99s-of-brooklyn---sunset-park-brooklyn-32404699",
    logo: Doordash,
  },
  {
    name: "Grubhub",
    url: "https://www.grubhub.com/restaurant/robs-of-brooklyn--sunset-park-4201-7th-avenue-brooklyn/10046816",
    logo: Grubhub,
  },
  {
    name: "Seamless",
    url: "https://www.seamless.com/menu/robs-of-brooklyn--sunset-park-4201-7th-avenue-brooklyn/10046816",
    logo: Seamless,
  },
  {
    name: "Slice",
    url: "https://slicelife.com/restaurants/ny/brooklyn/11232/rob-s-of-brooklyn-sunset-park/menu?utm_campaign=order_now_button&utm_medium=referral&utm_source=robsofbrooklyn.com",
    logo: Slice,
  },
  {
    name: "Postmates",
    logo: Postmates,
  },
  {
    name: "Uber Eats",
    logo: UberEats,
  },

]

const locations = [
  {
    location: "Bensonhurst",
    phone: "(347) 587-5777",
    platforms: bensonhurstPlatforms,
    isActive: true
  },
  {
    location: "Sunset Park",
    phone: "(347) 599-0179",
    platforms: sunsetParkPlatforms,
    isActive: true
  }
];

const LocationOrderCard = ({ location }) => (
  <div className="flex flex-col gap-8 bg-white rounded-xl p-8 shadow-md border-2 border-brand-tan">
    <div className="text-center">
      <h2 className="font-rig text-4xl text-brand-red mb-2 flex items-center justify-center gap-3">
        {location.location}
        {location.location === "Sunset Park" &&
          <span className="bg-brand-green text-white text-sm font-bold font-typewriter py-1 px-3 rounded-md">
            New
          </span>
        }
      </h2>
    </div>

    <div className="text-center">
      <h3 className="font-typewriter text-lg mb-4">Call to Order</h3>
      <a href={`tel:+1${location.phone.replace(/\D/g, '')}`}>
        <span className="flex items-center justify-center px-6 py-3 bg-brand-red text-white rounded-md text-2xl font-typewriter font-bold hover:bg-brand-red-dark transition-colors">
          <PhoneCall weight="fill" className="mr-3 h-6 w-6" />
          {location.phone}
        </span>
      </a>
    </div>

    <div className="flex items-center gap-4">
      <hr className="flex-1 border-2 border-brand-green" />
      <span className="font-typewriter text-brand-green font-bold">or</span>
      <hr className="flex-1 border-2 border-brand-green" />
    </div>

    <div className="text-center">
      <h3 className="font-typewriter text-lg mb-6">Order Online</h3>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {location.isActive ? (
          location.platforms.map((e) => (
            <a
              href={e.url}
              target="_blank"
              rel="noreferrer"
              key={e.name}
              className={`flex rounded-lg justify-center items-center p-6 border-2 ${
                e.url ? 'hover:bg-gray-50 hover:border-brand-red/20 cursor-pointer' : 'cursor-not-allowed opacity-50'
              } transition-colors h-[120px]`}
            >
              {e.url ? (
                <img className="h-6 sm:h-8" src={e.logo} alt={`${e.name} logo`} />
              ) : (
                <div className="flex flex-col items-center">
                  <img className="h-6 sm:h-8 mb-2" src={e.logo} alt={`${e.name} logo`} />
                  <span className="text-xs font-typewriter">Coming Soon</span>
                </div>
              )}
            </a>
          ))
        ) : (
          <div className="col-span-2 sm:col-span-3 py-8 text-xl font-typewriter font-bold text-brand-red">
            Online Ordering Coming Soon!
          </div>
        )}
      </div>
    </div>
  </div>
);

const Order = () => {
  return (
    <div className="flex flex-col w-full mx-auto">
      <Hero />
      <div id="order" className="flex flex-col py-20 bg-brand-tan/20">
        <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto px-4">
          {locations.map((location, index) => (
            <LocationOrderCard key={index} location={location} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Order;