import React from "react";

const Pizza = ({ menuData }) => {
  // If no menuData is provided, return null or a loading state
  if (!menuData) return null;

  return (
    <div>
      <h2 className="flex font-rig text-brand-red text-4xl justify-center mb-4">
        Pizza
      </h2>
      <div className="flex flex-col text-center font-typewriter text-xs mb-2">
        <span>
          All pizza pies are 20-inch large round pies unless otherwise
          indicated.
        </span>
      </div>
      <div className="flex font-typewriter font-bold justify-end text-brand-red text-sm">
        Slice / Pie
      </div>
      <div>
        <ul>
          {menuData.map((el) => {
            return (
              <li className="mb-4" key={el.name}>
                <div className="font-typewriter font-bold flex justify-between">
                  <span>{el.name}</span>
                  <span>
                    {el.price.slice && el.price.pie ? (
                      <span>
                        <sup>$</sup>
                        {el.price.slice} / <sup>$</sup>
                        {el.price.pie}
                      </span>
                    ) : (
                      <span>
                        <sup className="text-xs mr-1">pie only</sup>
                        <sup>$</sup>
                        {el.price.pie}
                      </span>
                    )}
                  </span>
                </div>
                <div className="font-typewriter-cond">
                  {el.description ? el.description : null}
                </div>
                <div className="text-brand-red ">
                  {el["add_on"]
                    ? el["add_on"].map((e) => {
                        return (
                          <div
                            key={e.name}
                            className="flex items-start justify-between font-typewriter font-medium"
                          >
                            {e.type.includes("Rob") ? (
                              <span className="flex-1">
                                * {e.type}: {e.name}
                              </span>
                            ) : (
                              <span className="flex-1">
                                + {e.type}: {e.name}
                              </span>
                            )}
                            <span className="font-bold">
                              {e.price.slice && e.price.pie ? (
                                <span>
                                  <sup>+ $</sup>
                                  {e.price.slice} / <sup>+ $</sup>
                                  {e.price.pie}
                                </span>
                              ) : (
                                <span>
                                  <sup>+ $</sup>
                                  {e.price.pie}{" "}
                                </span>
                              )}
                            </span>
                          </div>
                        );
                      })
                    : null}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Pizza;