import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { MenuContext } from "../MenuContext";

const navigationItems = [
  { name: "Home", url: "/" },
  { name: "Menu", url: "/menu" },
  { name: "Gallery", url: "/gallery" },
  // { name: "Press", url: "/press" },
  { name: "Merch", url: "https://shop.robsofbrooklyn.com", external: true },
  { name: "Location & Hours", url: "/contact#location-and-hours" },
  { name: "Contact", url: "/contact#form" },
  { name: "Order 🍕", url: "/order" },
];

const Navigation = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(MenuContext);

  const location = useLocation();
  const currentUrl = location.pathname;

  const handleNavigationItemSelected = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <ul className="flex flex-col lg:flex-row lg:items-center mt-4 lg:mt-0 gap-3 lg:gap- text-lg font-typewriter font-bold text-white
    ">
      {navigationItems.map((el) => {
        return (
          <li key={el.name} onClick={handleNavigationItemSelected}>
            {el.external ? (
              <a
                href={el.url}
                target="_blank"
                rel="noreferrer"
                className="flex cursor-pointer rounded-md px-3 py-1 transition ease-in-out duration-300 hover:bg-white hover:text-brand-red"
              >
                {el.name}
              </a>
            ) : (
              <Link
                className={`cursor-pointer rounded-md px-3 py-1 transition ease-in-out duration-300
              ${
                currentUrl === el.url
                  ? "flex bg-white text-brand-red"
                  : "flex hover:bg-white hover:text-brand-red"
              }`}
                to={el.url}
              >
                {el.name}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default Navigation;
