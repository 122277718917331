import React from "react";
import CoverImage from "../../assets/robs-of-brooklyn-coney-island.png";
import { Link } from "react-router-dom";

const Merch = () => {
  return (
    <div className="flex flex-col lg:flex-row">
      {/* Image section */}
      <div className="bg-brand-red w-full lg:w-1/2 order-2 lg:order-2">
        <img
          src={CoverImage}
          alt="Plate of meatballs in red sauce"
          className="h-auto w-full object-cover lg:object-center"
        />
      </div>

      {/* Text section */}
      <div className="bg-white py-8 px-4 lg:px-8 w-full lg:w-1/2 order-2 lg:order-1 flex flex-col items-center justify-center">
        <h2 className="flex font-rig text-brand-red text-6xl justify-center mb-4">
          Shop
        </h2>
        <div className="flex flex-col px-4 sm:px-0 gap-4 max-w-lg mx-auto text-center font-typewriter">
          <p className="text-xl py-8">Shop our latest merch.</p>
          <Link
            className="block w-full min-w-[180px]text-center text-white bg-brand-red py-2 px-6 font-typewriter rounded-md font-bold text-xl hover:bg-brand-red-dark transition-colors duration-200"
            to="https://shop.robsofbrooklyn.com"
            target="_blank"
            rel="referrer"
          >
            Visit Shop
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Merch;
