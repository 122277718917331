import React from "react";

const Map = ({ location }) => {
  const mapUrls = {
    "Bensonhurst": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.4259872720386!2d-74.00029202391717!3d40.598424071410996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2459bbeb31ae9%3A0x44d30cd1cf9dc8b4!2sRob's%20of%20Brooklyn!5e0!3m2!1sen!2sus!4v16845986215",
    "Sunset Park": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.8456087301785!2d-73.9984843!3d40.6325675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25ad52bae1355%3A0x4f0f75e1e2bda717!2s4201%207th%20Ave%2C%20Brooklyn%2C%20NY%2011232!5e0!3m2!1sen!2sus!4v1684598621520!5m2!1sen!2sus"
  };

  // If no location provided, default to Bensonhurst
  const mapUrl = location ? mapUrls[location.location] : mapUrls["Bensonhurst"];

  return (
    <div>
      <iframe
        className="mx-auto min-h-[300]"
        title={`${location?.location || 'Bensonhurst'} Location Map`}
        src={mapUrl}
        width="100%"
        height="400"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3027.2350165688795!2d-74.0037356241669!3d40.646749541607925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b5b00357e43%3A0x780ebb80e80eccc1!2sRob&#39;s%20of%20Brooklyn%20%E2%80%93%20Sunset%20Park!5e0!3m2!1sen!2sus!4v1734572790392!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
    </div>
  );
};

export default Map;