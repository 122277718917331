import React from "react";
import JobApplicationForm from "../components/JobApplicationForm";

const Jobs = () => {
  return (
    <div className="flex flex-col 2xl:container gap-4 justify-center w-full md:w-1/2 mx-auto py-12 px-4">
      <div className="flex flex-col items-center">
      <h1 className="font-rig text-brand-red text-6xl text-center mb-4">
        Join Our Team
      </h1>
      <p className="font-typewriter text-center max-w-lg">
        We are always looking for talented individuals to join our team. Please fill out the form below and we will get back to you as soon as possible.
      </p>
      </div>
      <JobApplicationForm />
    </div>
  );
};

export default Jobs;
