import React, { useEffect, useState } from "react";
import { CircleNotch } from "phosphor-react";

const ContactForm = () => {
  const [userInput, setUserInput] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    reason: "General Inquiry",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    if (
      userInput.fullName ||
      userInput.email ||
      userInput.phoneNumber ||
      userInput.reason
    ) {
      setError(false);
    }
    return;
  }, [userInput]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (
      !userInput.fullName ||
      !userInput.email ||
      !userInput.phoneNumber ||
      !userInput.reason
    ) {
      setSubmissionError("Please fill out all required fields.");
      setError(true);
      setIsSubmitting(false);
      return;
    } else {
      setError(false);
      setIsSubmitting(true);
    }

    const url = process.env.REACT_APP_API_BASE_URL + "/contact";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...userInput }),
    };

    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        setError(true);
        setIsSubmitting(false);
        return;
      }

      const result = await response.json();

      if (result.error) {
        setSubmissionError(result.json.error);
        setIsSubmitting(false);
        return;
      }

      setIsSubmitting(false);
      setMessageSent(true);
      setUserInput({
        fullName: "",
        email: "",
        phoneNumber: "",
        reason: "General Inquiry",
        message: "",
      });

      setTimeout(() => {
        setMessageSent(false);
      }, 5000);
    } catch (error) {
      setError(true);
      setSubmissionError(error.message);
      setIsSubmitting(false);
    }
  };

  return (
    <div className="mx-auto font-typewriter">
      <h2 className="font-rig text-brand-red text-6xl text-center mb-4">
        Get in Touch
      </h2>
      <form className="max-w-lg mx-auto px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="fullName">
            Full Name <span className="text-brand-red">*</span>
          </label>
          <input
            value={userInput.fullName}
            onChange={(event) => {
              setUserInput({ ...userInput, fullName: event.target.value });
            }}
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="fullName"
            type="text"
            placeholder="Full Name"
            required
          />
        </div>
        <div className="mb-4 flex flex-col sm:flex-row gap-4">
          <div className="w-full">
            <label className="block text-sm font-bold mb-2" htmlFor="email">
              Email <span className="text-brand-red">*</span>
            </label>
            <input
              value={userInput.email}
              onChange={(event) => {
                setUserInput({ ...userInput, email: event.target.value });
              }}
              className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              required
            />
          </div>
          <div className="w-full">
            <label
              className="block text-sm font-bold mb-2"
              htmlFor="phoneNumber"
            >
              Phone Number <span className="text-brand-red">*</span>
            </label>
            <input
              value={userInput.phoneNumber}
              onChange={(event) => {
                setUserInput({ ...userInput, phoneNumber: event.target.value });
              }}
              className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="phoneNumber"
              type="tel"
              placeholder="Phone Number"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="reason">
            Reason <span className="text-brand-red">*</span>
          </label>
          <select
            value={userInput.reason}
            onChange={(event) => {
              setUserInput({ ...userInput, reason: event.target.value });
            }}
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="reason"
          >
            <option value="General Inquiry">General Inquiry</option>
            <option value="Catering">Catering</option>
            <option value="Press">Press</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            value={userInput.message}
            onChange={(event) => {
              setUserInput({ ...userInput, message: event.target.value });
            }}
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="message"
            rows="4"
            placeholder="Your message..."
          />
        </div>
        {error ? (
          <div className="flex items-center bg-red-50 font-bold tracking-wide text-brand-red-dark py-2 px-4 mb-4 rounded-sm">
            <div>{submissionError}</div>
          </div>
        ) : null}
        {messageSent ? (
          <div className="flex items-center bg-green-50 font-bold tracking-wide text-brand-green py-2 px-4 mb-4 rounded-sm">
            <div className="">
              Your message has been successfully sent. We'll be in touch soon!
            </div>
          </div>
        ) : null}
        <div className="flex items-center justify-center">
          <button
            className={`flex gap-2 justify-center w-full font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
            ${isSubmitting
                ? "bg-red-50 text-brand-red-dark cursor-not-allowed"
                : "bg-brand-red hover:bg-brand-red-dark text-white"
              }`}
            type="button"
            onClick={handleFormSubmit}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircleNotch weight={"bold"} className="animate-spin" size={24} />
            ) : null}
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
