import React, { useState } from "react";
import Pizza from "../components/menu/Pizza";
import GrabNGo from "../components/menu/GrabNGo";
import Rolls from "../components/menu/Rolls";
import Calzones from "../components/menu/Calzones";
import Appetizers from "../components/menu/Appetizers";
import SoupAndSalads from "../components/menu/SoupAndSalads";
import Pasta from "../components/menu/Pasta";
import Entrees from "../components/menu/Entrees";
import Heros from "../components/menu/Heros";
import Sides from "../components/menu/Sides";
import Desserts from "../components/menu/Desserts";
import Beverages from "../components/menu/Beverages";
import { useNavigate } from "react-router-dom";
import bensonhurstMenu from "../menu-bensonhurst.json";
import sunsetParkMenu from "../menu-sunset-park.json";
import { ArrowUpRight } from "phosphor-react";

const Menu = () => {
  const [selectedLocation, setSelectedLocation] = useState("bensonhurst");
  const navigate = useNavigate();

  const menuData = selectedLocation === "sunset-park" ? sunsetParkMenu : bensonhurstMenu;

  const handleCateringRequest = () => {
    navigate("/contact#form");
  };

  return (
    <>
      {/* Location Toggle */}
      <div className="sticky top-0 bg-white shadow-md z-10">
        <div className="2xl:container mx-auto flex justify-center gap-4 py-4">
          <button
            className={`font-typewriter px-4 py-2 rounded-full transition-colors ${
              selectedLocation === "bensonhurst"
                ? "bg-brand-red text-white"
                : "border-2 border-brand-red text-brand-red hover:bg-brand-red/10"
            }`}
            onClick={() => setSelectedLocation("bensonhurst")}
          >
            Bensonhurst
          </button>
          <button
            className={`font-typewriter px-4 py-2 rounded-full transition-colors ${
              selectedLocation === "sunset-park"
                ? "bg-brand-red text-white"
                : "border-2 border-brand-red text-brand-red hover:bg-brand-red/10"
            }`}
            onClick={() => setSelectedLocation("sunset-park")}
          >
            Sunset Park
          </button>
        </div>
      </div>

      <div className="2xl:container flex flex-col gap-4 w-full md:w-1/2 mx-auto py-12 px-4">
        <Pizza menuData={menuData.menu.pizza} />
        <GrabNGo menuData={menuData.menu.grabNGo} />
        <Rolls menuData={menuData.menu.rolls} />
        <Calzones menuData={menuData.menu.calzones} />
        <Appetizers menuData={menuData.menu.appetizers} />
        <SoupAndSalads menuData={menuData.menu['soup-and-salads']} />
        <Pasta menuData={menuData.menu.pasta} />
        <Entrees menuData={menuData.menu.entrees} />
        <Heros menuData={menuData.menu.heros} />
        <Sides menuData={menuData.menu.sides} />
        <Desserts menuData={menuData.menu.desserts} />
        <Beverages menuData={menuData.menu.beverages} />

        <div className="flex font-typewriter mx-auto text-lg border-y w-full items-center justify-center py-4">
          <span> Catering available upon request</span>
          <ArrowUpRight
            onClick={handleCateringRequest}
            className="ml-2 cursor-pointer hover:text-brand-red"
            size={24}
          />
        </div>
        <div className="text-center text-xs font-typewriter">
          Prices are subject to change without notice
        </div>
      </div>
    </>
  );
};

export default Menu;