import React, { useEffect, useState } from "react";
import Map from "../components/contact/Map";
import ContactForm from "../components/contact/ContactForm";

const Contact = () => {
  const [currentHash, setCurrentHash] = useState(null);

  const locations = [
    {
      location: "Bensonhurst",
      address: {
        text: "8774B Bay Pkwy\nBrooklyn, NY 11214",
        link: "https://goo.gl/maps/wdekH6DRL5X5Kxg18"
      },
      phone: "(347) 587-5777",
      email: "info@robsofbrooklyn.com",
      hours: "Monday – Sunday\n11 am – 10 pm",
    },
    {
      location: "Sunset Park",
      address: {
        text: "4201 7th Ave\nBrooklyn, NY 11232",
        link: "https://maps.app.goo.gl/U2CenEdKqtYtBgn99"
      },
      phone: "(347) 599-0179",
      email: "info@robsofbrooklyn.com",
      hours: "Monday – Sunday\n11 am – 10 pm",
    }
  ];

  useEffect(() => {
    setCurrentHash(window.location.hash.slice(1));
  }, [window.location.hash]);

  useEffect(() => {
    if (currentHash) {
      document
        .getElementById(currentHash)
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [currentHash]);

  const LocationCard = ({ location }) => (
    <div className="flex flex-col gap-6">
      <div className="h-[300px] w-full rounded-xl overflow-hidden border-4 border-brand-tan shadow-md">
        <Map location={location} />
      </div>
      <div className="text-center font-typewriter flex flex-col gap-4">
        <h2 className="text-lg font-bold text-brand-red">
          {location.location}
          {location.location === "Sunset Park" &&
            <span className="bg-brand-green text-white text-xs font-bold py-1 px-2 rounded-md ml-2">
              New
            </span>
          }
        </h2>
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href={location.address.link}
            target="_blank"
            rel="noreferrer"
          >
            {location.address.text.split('\n').map((line, i) => (
              <React.Fragment key={i}>
                {line}<br />
              </React.Fragment>
            ))}
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto" />
        <div>
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href={`tel:+1${location.phone.replace(/\D/g, '')}`}
          >
            {location.phone}
          </a>
          <br />
          <a
            className="underline hover:text-brand-red cursor-pointer"
            href={`mailto:${location.email}`}
          >
            {location.email}
          </a>
        </div>
        <hr className="w-8 border-2 border-brand-green mx-auto" />
        <div>
          {location.hours.split('\n').map((line, i) => (
            <React.Fragment key={i}>
              {i === 0 ? <span>{line}</span> : line}<br />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="pt-12 px-4">
      <div id="location-and-hours" className="max-w-6xl mx-auto">
        <h2 className="font-rig text-brand-red text-6xl text-center mb-12">
          Visit Us
        </h2>

        <div className="grid md:grid-cols-2 gap-16 max-w-4xl mx-auto mb-16">
          {locations.map((location, index) => (
            <LocationCard key={index} location={location} />
          ))}
        </div>

      </div>
      <div id="form" className="bg-brand-tan/50 py-12 mx-auto">
        <ContactForm />
      </div>
    </div>
  );
};

export default Contact;