import React from "react";
import BayNews from "../../assets/brand-logos/BayNews.png";
import YouTube from "../../assets/brand-logos/youtube.svg";
import OneBite from "../../assets/brand-logos/one-bite.svg";
import Infatuation from "../../assets/brand-logos/infatuation.svg";

const FeaturedIn = () => {
  return (
    <div className="bg-stone-50 flex flex-col  py-6">
      <div className="container mx-auto">
        <h2 className="flex font-typewriter text-sm font-bold justify-center mb-4">
          Featured In
        </h2>
        <div className="flex items-center justify-center px-4 sm:px-0 gap-8 mx-auto text-xl font-typewriter">
          <a href="https://m.youtube.com/watch?v=3t0rNXz3A2k&pp=ygUScm9iJ3Mgb2YgYnJvb2tseW4g" target="_blank" rel="noreferrer">
            <img src={OneBite} alt="One Bite Logo" className="h-10" />
          </a>
          <a href="https://www.theinfatuation.com/new-york/reviews/robs-of-brooklyn" target="_blank" rel="noreferrer">
            <img src={Infatuation} alt="Infatuation Logo" className="h-5" />
          </a>
          <a
            href="/BayNews.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img src={BayNews} alt="Bay News Logo" className="h-5" />
          </a>
          <a href="https://youtu.be/Z1Y9MgaDE40" target="_blank" rel="noreferrer">
            <img src={YouTube} alt="YouTube Logo" className="h-5" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedIn;
